<template>
	<div>
		<DateRange
            :start.sync="start_date"
            :end.sync="end_date"
            @submit="init_component"
            :ShowTodayButton="true"
        />

		<CustomTable
			ref="table"
			id_table="deplacements"
			:busy.sync="table_busy"
			primaryKey="deplacement_id"
			:hide_if_empty="true"
			:items="deplacement"
			:externSlotColumns="['chauffeur','horse']"
		>
			<template v-slot:[`custom-slot-cell(horse)`]="{ data }">
				<template v-for="horse in data.horse">
					<router-link :key="horse.horse_id" :to="{ name: 'HorseFicheInfo', params: { horse_id: horse.horse_id }}">
						{{ horse.horse_nom }}<br>
					</router-link>
				</template>
			</template>
			<template v-slot:[`custom-slot-cell(chauffeur)`]="{ data }">
				<router-link v-if="data.chauffeur" :key="data.chauffeur.contact_id" :to="{ name: 'contactFiche', params: { contact_id: data.chauffeur.contact_id }}">
					{{ data.chauffeur.contact_civility }} {{ data.chauffeur.contact_firstname }} {{ data.chauffeur.contact_lastname }}
				</router-link>
			</template>
		</CustomTable>
    </div>
</template>

<script type="text/javascript">
    import Shutter from '@/mixins/Shutter.js'
	import Deplacement from "@/mixins/Deplacement.js"
	import Navigation from "@/mixins/Navigation.js"
	import ShutterDeplacement from '@/mixins/shutters-manager/Deplacement.js'
	import { EventBus } from 'EventBus'

	export default {
		name: 'DeplacementListe',
		mixins: [Shutter, Deplacement, Navigation, ShutterDeplacement],
		data () {
			return {
				table_busy: false,
				deplacement: [],
				events_tab: {
					'TableAction::goToAddDeplacement': this.openAjoutDeplacement,
					'TableAction::goToEditDeplacement': this.openEditDeplacement,
					'TableAction::goToPdfDeplacement': this.pdfDeplacement,
					'TableAction::goToDeleteDeplacement': this.deleteSelectedDeplacement
				},
				start_date: new Date(new Date().setDate(new Date().getDate() - 15)),
				end_date: new Date(new Date().setDate(new Date().getDate() + 15)),
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.table_busy = true
				this.deplacement = await this.getAllDeplacements(this.start_date.toDateInputValue(), this.end_date.toDateInputValue())
				this.table_busy = false
			},

			deplacementFormSubmit() {
				this.$refs.table.unselectAll()
				this.init_component()
			},

			async pdfDeplacement(deplacement) {
				await this.printDeplacement(deplacement.deplacement_id)
				EventBus.$emit('TableAction::stopSpin')
			},

			async deleteSelectedDeplacement(deplacements) {
				const deplacements_ids = deplacements.map(dep => dep.deplacement_id)
				await this.deleteDeplacement(deplacements_ids)

				this.deplacementFormSubmit()
			}
		},

		components: {
			HeaderTab: () => import('@/components/HeaderTab'),
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
            DateRange: () => import('GroomyRoot/components/Inputs/DateRange')
		}
	}

</script>