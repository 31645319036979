import Constants from '../../config.local.js'
import Common from '@/assets/js/common'

export default {
	methods: {
		async getAllVehicules() {
            const url = Constants.VEHICULE_BASE +'?licence_key='+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("DeplacementMixins::getAllVehicules", url)
            .catch(error => {
                console.error("DeplacementMixins::getAllVehicules => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
		},

		async editVehicule(vehicule_id, params) {
            const url = this.constructRoute(Constants.VEHICULE_BY_ID, { vehicule_id }) +'?licence_key='+Constants.USER_LICENCE_KEY
            return await this.$request.request_post_api("DeplacementMixins::editVehicule", url, params, false)
		},

		async createVehicule(params) {
            const url = Constants.VEHICULE_BASE +'?licence_key='+Constants.USER_LICENCE_KEY
            return await this.$request.request_post_api("DeplacementMixins::createVehicule", url, params, false)
		},

        async archiveOrUnarchiveVehicule(vehicule_id) {
            const url = this.constructRoute(Constants.VEHICULE_ARCHIVE, { vehicule_id }) +'?licence_key='+Constants.USER_LICENCE_KEY
            return await this.$request.request_post_api("DeplacementMixins::archiveOrUnarchiveVehicule", url, {}, false)
        },

		async getAllDeplacements(start, end) {
            const url = Constants.DEPLACEMENT_BASE +'?licence_key='+Constants.USER_LICENCE_KEY+'&start='+start+'&end='+end
			const result = await this.$request.request_get_api("DeplacementMixins::getAllDeplacements", url)
            .catch(error => {
                console.error("DeplacementMixins::getAllDeplacements => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
		},

        async editDeplacement(deplacement_id, params, mouvement) {
            const url = this.constructRoute(Constants.DEPLACEMENT_BY_ID, { deplacement_id }) +'?licence_key='+Constants.USER_LICENCE_KEY+'&mouvement='+mouvement
            return await this.$request.request_post_api("DeplacementMixins::editDeplacement", url, params, false)
        },

        async createDeplacement(params, mouvement) {
            const url = Constants.DEPLACEMENT_BASE +'?licence_key='+Constants.USER_LICENCE_KEY+'&mouvement='+mouvement
            return await this.$request.request_post_api("DeplacementMixins::createDeplacement", url, params, false)
        },
        async printDeplacement(deplacement_id) {
            const url = this.constructRoute(Constants.DEPLACEMENT_PDF_BY_ID, { deplacement_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("DeplacementMixins::printDeplacement", url)
            .catch(error => {
                console.error("DeplacementMixins::printDeplacement => ERROR", error)
                return null
            })
            Common.base64ToPdf(result, "transport.pdf")
            return true
        },

        async getSuggestionDeplacements(date) {
            const url = this.constructRoute(Constants.DEPLACEMENT_SUGGESTION, { date }) +'?licence_key='+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("DeplacementMixins::getSuggestionDeplacements", url)
            .catch(error => {
                console.error("DeplacementMixins::getSuggestionDeplacements => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

        async deleteDeplacement(deplacement_ids) {
            const url = this.constructRoute(Constants.DEPLACEMENT_BY_ID, { deplacement_id: deplacement_ids.join(',') }) +'?licence_key='+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_delete_api("DeplacementMixins::deleteDeplacement", url)
            .catch(error => {
                console.error("DeplacementMixins::deleteDeplacement => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        }
	}
}
